import React from 'react'

import '../../css/Footer.css'
import { Link } from 'react-router-dom'
function Footer() {
    return (
        <div className='footer-container'>
           {/* <section className="footer-subscription">
               <p className="footer-subscription-heading">
                   join US
               </p>

               <p className="footer-subscription-text">
                   you can unsubscribe at anytime
               </p>

               <div className="input-areas">
                   <form>
                       <input type='email' name="email" placeholder="Your Email"
                       className="footer-input"/>
                       <Button buttonStyle='btn--outline'>Subscribe</Button>
                   </form>
               </div>
            </section>   */}
            {/* <div className="footer-links">
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to='/sign-up'>How it works</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>T</Link>
                        <Link to='/'>Test</Link>
                    </div>
                </div>
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to='/sign-up'>How it works</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>T</Link>
                        <Link to='/'>Test</Link>
                    </div>
                </div>
                <div className="footer-link-wrapper">
                    <div className="footer-link-items">
                        <h2>About Us</h2>
                        <Link to='/sign-up'>How it works</Link>
                        <Link to='/'>Testimonials</Link>
                        <Link to='/'>Careers</Link>
                        <Link to='/'>T</Link>
                        <Link to='/'>Test</Link>
                    </div>
                </div>
            </div> */}
            <section className="social-media">
                <div className="social-media-wrap">
                    {/* <div className="footer-logo">
                        <Link className="social-logo">
                            <img src="images/img-wechat.png" className="wechat-code"/>
                            <p>请微信我们获得即时的回复 </p>
                        </Link>
                    </div> */}
                    {/* <small className="website-rights">
                        
                        
                        <p className='wr-fonts'>电子邮件</p>
                        <p className='wr-fonts'>zigzagusinc@gmail.com</p>
                         
                    </small> */}
                    <small className="website-rights">
                        <p className='wr-fonts'> © 2010 by EverydaySpa {'&'} SkinCare Inc.All rights reserved.</p>
                        {/* <p className='wr-fonts'>  All rights reserved.</p> */}
                         
                    </small>
                
                    {/* <div className="social-icons">
                        <Link className="social-icon-link facebook"
                        to="/"
                        target="_blank"
                        aria-label="Facebook">
                        
                            <i className="fab fa-facebook-f"></i>
                        </Link>
                        <Link className="social-icon-link instagram"
                        to="/"
                        target="_blank"
                        aria-label="instagram">
                        
                            <i className="fab fa-facebook-f"></i>
                        </Link>
                    </div> */}
                  
                </div>
            </section>
        </div>
    )
}

export default Footer
