import React, { Component } from 'react'
import '../../css/Homefoot.css'
import foot1 from '../../images/massagefoot.png'
import Homecombo from './Homecombo'


export class Homefoot extends Component {
    render() {
        return (
            <div className="homefoot-container" id="our-services">
               <div className="homefoot-wrapper">
                   <div className="homefoot-left">
                     
                       
                   <img src={foot1}  alt='img-foot1' /> 
    
                     
                   </div>
                   <div className="homefoot-right">

                    


                       <h2>Foot Reflexology</h2>

                        <p>  Everyday Spa foot massage's primary goal is to apply pressure to points that relax the entire body's muscles. Our massage services are highly beneficial for increasing the level of oxygen in the blood, decreasing muscle toxins, and improving circulation while easing tension. For more information on our massage spa services, please contact Everyday Spa today.</p>
                        <div  className='move-to-right'>
                        <div className='homefoot-display-flex'>
                        <h3>30 mins</h3> 
                         <h4>25$</h4>
                        </div>
                        <div className='homefoot-display-flex'>
                        <h3>60 mins</h3>  
                        <h4>35$</h4>
                        </div>
                        <div className='homefoot-display-flex'>
                        <h3>90 mins</h3>  
                        <h4>55$</h4>
                        </div>
                        <div className='homefoot-display-flex'>
                        <h3>120 mins</h3>  
                        <h4>70$</h4>
                        </div>
                        </div>

                   </div>
               </div>

               <div className="homefoot-wrapper">

                   <Homecombo />
                   </div>

            
            </div>
        )
    }
}

export default Homefoot
