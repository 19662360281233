import React, { Component } from 'react'
import '../../css/Homefacial.css'

import deeptissue from '../../images/7.png'

export class Homefacial extends Component {
    render() {
        return (
            <div className="homefacial-container"  id="our-services">
               <div className="homefacial-wrapper">
               
                   <div className="homefacial-left">
                   <h2>Facial</h2>
                   <p>Experience full body massage sessions from Everyday Spa and discover the pain relief and personal rejuvenation that you can get from our massage services. We target your massage session to address the strongest points of stress in your body so that we can quickly and thoroughly get you back to feeling great. Relaxation and pain relief are the primary benefits of our massages. Visit us at Everyday spa today to discover the pain relief and regeneration you can get from our massage parlor.</p>
                        
                            <h4 className='homefacial'>Deep Cleasing facial</h4>
                        <div className='homefacial-display-flex'>
                            
                        <h3>60-75mins </h3> 
                         <h4>59$</h4>
                        </div>
                        <h4 className="homefacial">24k Golden facial</h4>
                        <div className='homefacial-display-flex'>
                        <h3>60-75mins </h3>  
                        <h4>65$</h4>
                        </div>
                      
                        
                      
                        
                        
                   </div>
                   <div className="homefacial-right">

                      <img src={deeptissue}  alt='img-shopping' /> 
                   </div>
               </div>

             
            </div>
        )
    }
}

export default Homefacial
