import React, { Component } from 'react'
import './../../css/Hometop.css'
import { Link } from 'react-router-dom'

export class Hometop extends Component {
    render() {
        return (
            <>
          
            <div className='hometop-container'>
                <div className='hometop-wrapper'>
                    <div className='hometop-title'>
                       
                        <p className='p22'>Best Spa In Merrick NY Since 2010 </p>
                        <p className='p22'>Rejuvenate your body, mind and soul</p>
                                
                          <div className='ccus'>
                                 <a href="tel:5163855700" className="contact-us" style={{textDecoration:'none'}}>Contact Us</a>
                             </div>
                    </div>
                </div>
            </div>

            </>
        )
    }
}

export default Hometop
