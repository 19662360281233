import React, { Component } from 'react'
import '../../css/Homemidtitle.css'
import deeptissue from './../../images/bookimg.png'
export class Homemidtitle extends Component {
    render() {
        return (
            <div className='hmt-container'>
                   

               
                   <h1> Book Now!</h1>
                    <h1>Reach out to us! We’re always here to help.</h1>
                    <h1>Tel:516-385-5700</h1>
                    

                   
                
            </div>
        )
    }
}

export default Homemidtitle
