import React, { Component } from 'react'
import '../../css/Contact.css'
export class Contact extends Component {
    render() {
        return (
            <div className='contact-container' > 
                <div className='contact-title'>
                    <h1 className='p3'>About Us</h1>
                    <h2 className='p2'>Unwind, destress, relax, rejuvenate.</h2>
                    <p className='p2'>Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing.</p>
                </div>
              
            </div>
        )
    }
}

export default Contact
