import React, { Component } from 'react'
import '../../css/Pam.css'
import {Helmet} from 'react-helmet'
import {Map,InfoWindow,Marker,GoogleApiWrapper} from 'google-maps-react'
export class Phoneandmap extends Component {
    render() {
        return (
            <div className='pam-container' id='contact-spa'>
                  
          <Helmet>
          <title>Everyday Spa -New York |Massage,Deep Tissue, Facial, Skin Care, Prenatal Massage, Foot Reflexology,Body work</title>
              <meta name="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>

              <meta itemprop="name" content="EVERYDAY SPA"/>
              <meta itemprop="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta itemprop="image" content=""/>

              <meta property="og:url" content="https://everydayspany.com/ "/>
              <meta property="og:type" content="website"/>
              <meta property="og:title" content="EVERYDAY SPA"/>
              <meta property="og:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta property="og:image" content=""/>

             
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:title" content="EVERYDAY SPA"/>
              <meta name="twitter:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta name="twitter:image" content=""/>
           
          </Helmet>
                <div className ='pam-wrapper' >
                <div className='pam-title-left'>
                <a href="tel:5163855700" >Tel:516-385-5700</a>  
                    <h2>27A MERRICK AVE MERRICK NY 11566</h2>
                    <h2 >Hours:MON-SAT 10:30~9:30</h2>
                    <h2 >SUN     11:30~9:30</h2>
                    <h2> OPEN 7 DAYS</h2>
                
         
                
                </div>
                <div className='pam-title-right'>
                    <Map 
                    google={this.props.google} 
                    zoom={15}
                    initialCenter={{
                        lat:40.6644146,
                        lng:-73.5526212
                    }}
                //    style={{width: '25%',height: '50%'}}
                    >

                        <Marker onClick={this.onMarkerClick} name={'Current location'} />

                        <InfoWindow onClose={this.onInfoWindowClose}>
                        
                        </InfoWindow>
                    </Map>


                </div>

                </div>
              
            </div>
        )
    }
}




export default GoogleApiWrapper({
    apiKey: ("AIzaSyCEPMJtSPyrwee5quFxXGraf4oPHrwSl-k")
  })(Phoneandmap);