import React, { useState} from 'react'
import { Link } from 'react-router-dom'
import '../../css/Navbar.css'




function Navbar() {
    const [click,setClick] = useState(false);

    const handleClick = () =>setClick(!click);
    const closeMobileMenu= () =>setClick(false);


    return (
        <>
          <nav className="navbar">
              <div className="navbar-container">
                  <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                      Everyday Spa (516)-385-5700
                  
                  </Link>
                  <div className='menu-icon' onClick={handleClick}>
                      <i className={click ? 'fas fa-times' : 'fas fa-bars'} />

                  </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    
                  

                    
                  
                   

                    <li className='nav-item'>
                        <Link to="/" className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    
                    <li className='nav-item'>
                        <Link to="/our-services" className='nav-links' onClick={closeMobileMenu}>
                           Our Services
                        </Link>
                      
                    </li>

                    <li className='nav-item'>
                        <Link to="/contact-us" className='nav-links' onClick={closeMobileMenu}>
                            About Us
                        </Link>
                    </li>

                    <li className='nav-item'>
                        <Link to="/nav-giftcard" className='nav-links' onClick={closeMobileMenu}>
                            Gift Card
                        </Link>
                    </li>
                  
                  
                    
                    
                </ul>
              
              </div>
              
              
          </nav>
        </>
    )
}

export default Navbar
