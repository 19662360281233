import React, { Component } from 'react'
import '../../css/Homedeep.css'


import deeptissue from '../../images/5.png'

export class Homedeep extends Component {
    render() {
        return (
            <div className="homedeep-container"  id="our-services">
               <div className="homedeep-wrapper">
               
                   <div className="homedeep-left">
                   <h2>Deep Tissue</h2>
                   <p>Deep tissue massage bears similarities to Swedish massage and specifically targets the release of chronic tension patterns stored in your muscles. Deep tissue massage also releases toxins from your muscles and uses medium to intense pressure depending on your tolerance and preference. Everyday Spa can help you achieve the maximum benefits of massage using the deep tissue technique and have the qualified massage therapist do so. For more information on our massage spa services, please contact Everyday Spa today!</p>
                        <div  className="homedeep-left-price">
                        <div className='homedeep-display-flex'>
                        <h3>30 mins</h3> 
                         <h4>30$</h4>
                        </div>
                        <div className='homedeep-display-flex'>
                        <h3>60 mins</h3>  
                        <h4>49$</h4>
                        </div>
                        <div className='homedeep-display-flex'>
                        <h3>90 mins</h3>  
                        <h4>69$</h4>
                        </div>
                        <div className='homedeep-display-flex'>
                        <h3>120 mins</h3>  
                        <h4>98$</h4>
                        </div>
                        </div>
                      
                        
                        
                   </div>
                   <div className="homedeep-right">

                      <img src={deeptissue}  alt='img-shopping' /> 
                   </div>
               </div>

              
            </div>
        )
    }
}

export default Homedeep
