import React, { Component } from 'react'
import '../../css/Giftcard.css'

import deeptissue from '../../images/giftnewcard.png'

export class Giftcard extends Component {
    render() {
        return (
            <div className="giftcard-container"  >

                
               <div className="giftcard-wrapper">
                   
               
                      <div className="giftcard-top">

                      <img src={deeptissue}  alt='img-shopping' /> 
                     </div>

                     <div className='giftcard-bot'>

                         <h2>FREE HOT STONE!</h2>
                         <h2>GIFT CARDS AVAILABLE</h2>
                         <h2>BUY 5 GET 1 FREE</h2>
                         <h2>(BODY WORK OR DEEP TISSUE ONLY)</h2>

                         <p>So you’ve decided to show someone special your appreciation with a gift card to our Everyday spa. Thousands of our past satisfied customers agree with us when we say you’ve made a great choice!</p>
                         <h3>Please call us at</h3>
                         <a href="tel:5163855700" >516-385-5700</a> 
                         <h3>to purchase a gift card.</h3>
                         <p> Gift cards are not eligible for refund and can only be used in-person. </p>
                        
                     </div>
                     
                 
               </div>

             
            </div>
        )
    }
}

export default Giftcard
