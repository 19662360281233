import React, { Component } from 'react'
import deeptissue from '../../images/giftnewcard.png'
import '../../css/Navgiftcard.css'
import Helmet from 'react-helmet'
export class NavGiftcard extends Component {
    render() {
        return (
           

                
                <div className="navgiftcard-container"  >
                    <Helmet>
          <title>Everyday Spa -New York |Massage,Deep Tissue, Facial, Skin Care, Prenatal Massage, Foot Reflexology,Body work</title>
              <meta name="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>

              <meta itemprop="name" content="EVERYDAY SPA"/>
              <meta itemprop="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta itemprop="image" content=""/>

              <meta property="og:url" content="https://everydayspany.com/"/>
              <meta property="og:type" content="website"/>
              <meta property="og:title" content="EVERYDAY SPA"/>
              <meta property="og:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta property="og:image" content=""/>

             
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:title" content="EVERYDAY SPA"/>
              <meta name="twitter:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta name="twitter:image" content=""/>
           
          </Helmet>
                 <div className='navgiftcard-title'> 
                    <h1>GIFT CARD</h1>
                </div>
                <div className="navgiftcard-wrapper">
                    

                    <div className="navgiftcard-top">

                    <img src={deeptissue}  alt='img-shopping' /> 
                    </div>

                    <div className='navgiftcard-bot'>

                        <h2>FREE HOT STONE!</h2>
                        <h2>GIFT CARDS AVAILABLE</h2>
                        <h2>BUY 5 GET 1 FREE</h2>
                        <h2>(BODY WORK OR DEEP TISSUE ONLY)</h2>

                        <p>So you’ve decided to show someone special your appreciation with a gift card to our Everyday spa. Thousands of our past satisfied customers agree with us when we say you’ve made a great choice!</p>
                        <h3>Please call us at 516-385-5700 to purchase a gift card.</h3>
                        <p> Gift cards are not eligible for refund and can only be used in-person. </p>
                        
                    </div>
                    
                
                </div>


                </div>

             
    
        )
    }
}

export default NavGiftcard
