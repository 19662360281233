import React, { Component } from 'react'
import '../../css/Homebody.css'
import Homedeep from './Homedeep'
import deeptissue from '../../images/homebody.png'

export class Homebody extends Component {
    render() {
        return (
            <div className="homebody-container"  >
               <div className="homebody-wrapper">
               
                   <div className="homebody-left">

                   <img src={deeptissue}  alt='img-shopping' /> 
                   </div>
                   <div className="homebody-right">
                   <h2>Body Work</h2>
                   <p>Experience full body massage sessions from Everyday Spa and discover the pain relief and personal rejuvenation that you can get from our massage services. We target your massage session to address the strongest points of stress in your body so that we can quickly and thoroughly get you back to feeling great. Relaxation and pain relief are the primary benefits of our massages. Visit us at Everyday spa today to discover the pain relief and regeneration you can get from our massage parlor.</p>
                        <div className='move-to-right'>
                        <div className='homebody-display-flex'>
                        <h3>30 mins</h3> 
                         <h4>30$</h4>
                        </div>
                        <div className='homebody-display-flex'>
                        <h3>60 mins</h3>  
                        <h4>45$</h4>
                        </div>
                        <div className='homebody-display-flex'>
                        <h3>90 mins</h3>  
                        <h4>65$</h4>
                        </div>
                        <div className='homebody-display-flex'>
                        <h3>120 mins</h3>  
                        <h4>90$</h4>
                        </div>
                        </div>
                      
                        
                        
                   
                    
                   </div>
               </div>

               <div className="homebody-wrapper">
               <Homedeep />
               </div>

             
            </div>
        )
    }
}

export default Homebody
