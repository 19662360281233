import React, {Component} from 'react';
import {BrowserRouter ,Switch,Route} from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top'

/* pages */
import Home from './components/MainPage/Home'
import Contact from './components/ContactPage/Contact'
import Homefoot from './components/MainPage/Homefoot'
import Services from './components/Ourservice/Services'


import Navbar from './components/MainPage/Navbar'
import NavGiftcard from './components/Giftcard/NavGiftcard';





class App extends Component {

  render(){
    return (
        
        <>
       
          <BrowserRouter>
                     <ScrollToTop>
                     <Navbar />
           
            
         
          
                          <Switch>
                            
                            <Route path="/" exact component={Home} />
                          
                          
                        
                       
                          
                            <Route path='/our-services' component={Services}/>
                            <Route path='/contact-us' component={Contact} />
                            {/* <Route path='/service' component={Homefoot} /> */}
                            <Route path='/nav-giftcard' component={NavGiftcard} />

{/*                             
                            <Route path='/SendRequest' component={SendRequest} />
                            
                            <Route  component={PageNotFound} /> */}
                            
                          </Switch>
                        
                          
                    </ScrollToTop>
          </BrowserRouter>
          </>
       
    );
  }
  
  }
  export default App;
  
