import React, { Component } from 'react'
import '../../css/Homecombo.css'
import foot1 from '../../images/homecombo.png'


export class Homecombo extends Component {
    render() {
        return (
            <div className="homecombo-container">
               <div className="homecombo-wrapper">
                   <div className="homecombo-left">
                     
                        <h2>Combo</h2>

                        <p>  Our Combo massage sessions are based on traditional, classic, and relaxing techniques that incorporate sweeping strokes, kneading, percussion, and pressure to provide you ultimate relief and improved circulation.Combo massage is  including foot and back massage which give your body a full relaxing service. Our massages have a wide range of benefits, including pain relief, decreased stress within your body, increased mental clarity, improvement in your appearance, and greater flexibility. For more information on our massage spa services, please contact Everyday Spa today!</p>
                        <div  className='move-to-left'>
                        <div className='homecombo-display-flex'>
                        <h3>30mins foot{'&'}30mins back</h3> 
                         <h4>40$</h4>
                        </div>
                        <div className='homecombo-display-flex'>
                        <h3>60mins foot{'&'}30mins back</h3>  
                        <h4>60$</h4>
                        </div>
                        <div className='homecombo-display-flex'>
                        <h3>60mins foot{'&'}60mins back</h3>  
                        <h4>80$</h4>
                        </div>
                      
                        </div>
                 
    
                     
                   </div>
                   <div className="homecombo-right">

                   <img src={foot1}  alt='img-foot1' /> 


                      

                   </div>
               </div>

            
            </div>
        )
    }
}

export default Homecombo
