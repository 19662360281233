import React, { Component } from 'react'
import '../../css/Home.css'


import Hometop from './Hometop'
import Homefoot from './Homefoot'
import Homedeep from './Homedeep'
import Homemidtitle from './Homemidtitle'
import Homebody from './Homebody'
import Homecombo from './Homecombo'
import Homefacial from './Homefacial'
import Giftcard from './Giftcard'
import Footer from './Footer'
import Phoneandmap from '../Phoneandmap/Phoneandmap'
import Helmet from 'react-helmet'
function Home(){
     
        return (
            <div className="home-container">
                <Helmet>
          <title>Everyday Spa -New York |Massage,Deep Tissue, Facial, Skin Care, Prenatal Massage, Foot Reflexology,Body work</title>
              <meta name="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>

              <meta itemprop="name" content="EVERYDAY SPA"/>
              <meta itemprop="description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta itemprop="image" content=""/>

              <meta property="og:url" content="https://everydayspany.com/ "/>
              <meta property="og:type" content="website"/>
              <meta property="og:title" content="EVERYDAY SPA"/>
              <meta property="og:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta property="og:image" content=""/>

             
              <meta name="twitter:card" content="summary_large_image"/>
              <meta name="twitter:title" content="EVERYDAY SPA"/>
              <meta name="twitter:description" content="Everday Spa is clean and profession spa place open over 10 years on Merrick NY. We provide experince stuff and amazing service for our neighbor. Everyday Spa provides massage service that will ease sore muscles, stiff necks, and other aches you may be experiencing,massage ，foot rub，facial merrick ，body work，foot reflexology ，everyday spa，spa，relax，good place to relax，long island ，recommend"/>
              <meta name="twitter:image" content=""/>
           
          </Helmet>
              
                <Hometop />
                <Homemidtitle />
                
                <Homefoot />
               
               
                <Homebody />
               
                <Homefacial />
                <Giftcard />
                <Phoneandmap />
                
                <Footer />
                
            </div>
        )
    
}

export default Home
